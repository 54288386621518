export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic Model Overview"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A good economic model is the key to long-term sustainability of the product. NECO FUN rely on a circular economy with MINT/STAKE/BURN/BUYBACK recycling in a closed-loop system to ensure a long-term and stable development of the project."])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
        "neco-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO token, as the only platform token of NECO FUN, will establish dynamic connections to all NECO products/gaming tokens, and boast multiple functions including GOVERNANCE/VOTING/EARNING/STAKING."])},
        "bene-title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAX SUPPLY"])},
        "bene-title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO HOLDERS"])},
        "b2-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share 30% earnings"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经济模型总览"])},
        "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["良好的经济模型是产品长期生存的关键， NECO FUN将拥有完整闭环的产出/质押/销毁/回购经济模型， 以保证项目长期稳定的发展。"])},
        "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解详情"])},
        "neco-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO 币为项目唯一平台币，并将关联后续所有的NECO产品/代币，拥有管理/投票/分红/挖矿等诸多功能。"])},
        "bene-title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大供应量"])},
        "bene-title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO持有者"])},
        "b2-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共享平台产品30%的收益"])}
      }
    }
  })
}
